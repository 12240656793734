import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { IconButtonComponent } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import {
  ConnectionRequestDto,
  DocumentDto,
} from 'src/api/dso-portal/generated/models';
import { DocumentComponent } from './document/document.component';
import { ErrorHandlingService } from '@app/core/services';
import { DownloadRequestDirective } from '../../download-request.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'dso-documents',
  standalone: true,
  imports: [
    IconButtonComponent,
    TranslateModule,
    DocumentComponent,
    DownloadRequestDirective,
    MatIconModule,
  ],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
})
export class DocumentsComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #route = inject(ActivatedRoute);
  readonly #errorHandlingService = inject(ErrorHandlingService);

  readonly currentRequest: WritableSignal<ConnectionRequestDto | undefined> =
    signal(undefined);
  readonly documents: Signal<DocumentDto[]> = computed(() =>
    this.currentRequest()
      ? this.currentRequest()!.documentDetails.documents
      : []
  );

  public ngOnInit(): void {
    this.#route.parent?.data
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: ({ connectionRequest }) =>
          this.currentRequest.set(connectionRequest),
        error: error => {
          this.#errorHandlingService.handleError(error, {
            shouldRedirect: false,
            showErrorSnackbar: true,
          });
        },
      });
  }
}
