<div class="document">
  <div>
    <h2 class="document-headline">
      {{ 'DOCUMENT_TYPE.' + document?.type | translate }}
    </h2>

    <p class="document-description">
      <mat-icon
        class="dso-icon--small"
        svgIcon="file"
        [attr.aria-label]="
          'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate
        " />
      {{ document?.name }}
    </p>
  </div>
  <dso-icon-button
    class="download-button dso-card"
    icon="download"
    size="big"
    (click)="download()" />
</div>
