<div class="dso-card">
  <div class="documents-list">
    @for (document of documents(); track document.id) {
      <dso-document
        [connectionRequestId]="currentRequest()?.id || ''"
        [document]="document" />
    } @empty {
      <div class="documents-list-empty">
        <mat-icon
          class="dso-icon--small"
          svgIcon="info"
          [attr.aria-label]="
            'REQUEST_DETAILS.DOCUMENT_ICON_ALT_TEXT' | translate
          " />
        <p>{{ 'REQUEST_DETAILS.NO_DOCUMENTS_AVAILABLE' | translate }}</p>
      </div>
    }
  </div>

  @if (documents().length > 0) {
    <div class="documents-actions">
      <dso-icon-button
        data-testid="download-all-documents-btn"
        icon="download"
        size="medium"
        dsoDownloadRequest
        [request]="currentRequest()"
        type="ZIP">
        {{ 'REQUEST_DETAILS.DOWNLOAD_ALL_DOCUMENTS' | translate }}
      </dso-icon-button>
    </div>
  }
</div>
